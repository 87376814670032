import fetch, { useCustomSWR } from 'services/fetch';
import { CompanyType } from 'views/Home/components/Company/Company.type';
import { buildServiceUrl } from 'utils';

const MUTATE_STORES_KEY = buildServiceUrl('stores');

const limitPerPage = 18;

export const defaultResources = {
  getAll: `${MUTATE_STORES_KEY}`,
};

export function useCompany(category?: string | null, page?: number): {
    data: CompanyType[];
    companiesCount: number;
    limitPerPage: number;
    isValidating: boolean;
    loading: boolean;
    error?: unknown;
    mutate: any;
} {
  const { data: response, error, isValidating, mutate } = useCustomSWR<any>(
    category ? `${defaultResources.getAll}?category=${category}&page=${page}` : defaultResources.getAll,
    (url: string) => fetch.get(`${url}`)
  );

  return {
    data: response?.data?.stores || [],
    companiesCount: response?.count || 0,
    limitPerPage,
    mutate,
    isValidating,
    loading: !response?.data,
    error,
  };
}
