import Header from "./Header";
import Footer from "./Footer";
import 'preline';
import Summary from "./Summary";

const Cart = () => {
    return (
        <>
            <Header />
            <section className="s-cart w-full mt-6">
                <div className="container px-2 md:px-4 lg:px-6 xxl:px-0 grid md:grid-cols-12 gap-6">
                    <div className="md:col-start-1 md:col-end-9">
                        <div className="text grid gap-4 mb-8">
                            <h3 className="text-neutral-90 text-4xl font-semibold">Carrinho</h3>
                            <p className="text-neutral-90 text-base font-normal">Preencha os dados e finalize seu pedido</p>
                        </div>

                        <div className="hs-accordion-group grid gap-8" data-hs-accordion-always-open>
                            <div className="hs-accordion active rounded-2xl overflow-hidden border border-neutral-20" id="hs-basic-with-title-and-arrow-stretched-heading-one">
                                <button className="hs-accordion-toggle bg-neutral-10 group py-4 px-6 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-black text-xl transition" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                                    Dados pessoais
                                    <svg className="hs-accordion-active:hidden hs-accordion-active:group-hover:text-main-30 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg className="hs-accordion-active:block hs-accordion-active:group-hover:text-main-30 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-one" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
                                    <form className="grid gap-4 p-6" id="contact-form">
                                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                                            <label htmlFor="nome" className="relative text-xs font-semibold text-neutral-50">Nome</label>
                                            <input type="text" name="nome" id="nome"
                                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                placeholder="Ana Luísa" required />
                                        </div>
                                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                                            <label htmlFor="email" className="relative text-xs font-semibold text-neutral-50">E-mail</label>
                                            <input type="email" name="email" id="email"
                                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                placeholder="analuisa@email.com" required />
                                        </div>
                                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                                            <label htmlFor="telefone" className="relative text-xs font-semibold text-neutral-50">Telefone</label>
                                            <input type="tel" name="telefone" id="telefone"
                                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                placeholder="(00) 9 5555-5555" required />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="hs-accordion active rounded-2xl overflow-hidden border border-neutral-20" id="hs-basic-with-title-and-arrow-stretched-heading-two">
                                <button className="hs-accordion-toggle bg-neutral-10 group py-4 px-6 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-black text-xl transition" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
                                    Endereço
                                    <svg className="hs-accordion-active:hidden hs-accordion-active:group-hover:text-main-30 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg className="hs-accordion-active:block hs-accordion-active:group-hover:text-main-30 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-two" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
                                    <form className="grid gap-4 p-6" id="address-form">
                                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                                            <label htmlFor="cep" className="relative text-xs font-semibold text-neutral-50">CEP</label>
                                            <input type="text" name="cep" id="cep"
                                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                placeholder="00.000-00" required />
                                        </div>
                                        <div className="flex gap-4">
                                            <div className="relative z-0 w-full group grid gap-1 mb-4">
                                                <label htmlFor="rua" className="relative text-xs font-semibold text-neutral-50">Rua/Avenida</label>
                                                <input type="text" name="rua" id="rua"
                                                    className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                    placeholder="Av Ayrton Senna" required />
                                            </div>
                                            <div className="relative z-0 w-full group grid gap-1 mb-4">
                                                <label htmlFor="numero" className="relative text-xs font-semibold text-neutral-50">Número</label>
                                                <input type="text" name="numero" id="numero"
                                                    className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                    placeholder="Número" required />
                                            </div>
                                            <div className="relative z-0 w-full group grid gap-1 mb-4">
                                                <label htmlFor="complemento" className="relative text-xs font-semibold text-neutral-50">Complemento</label>
                                                <input type="text" name="complemento" id="complemento"
                                                    className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                    placeholder="Bl A Apto 201" required />
                                            </div>
                                        </div>
                                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                                            <label htmlFor="cidade" className="relative text-xs font-semibold text-neutral-50">Cidade</label>
                                            <input type="text" name="cidade" id="cidade"
                                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                placeholder="Natal" required />
                                        </div>
                                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                                            <label htmlFor="bairro" className="relative text-xs font-semibold text-neutral-50">Bairro</label>
                                            <input type="text" name="bairro" id="bairro"
                                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                                placeholder="Cidade Jardim" required />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="hs-accordion active rounded-2xl overflow-hidden border border-neutral-20" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                                <button className="hs-accordion-toggle bg-neutral-10 group py-4 px-6 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-black text-xl transition" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
                                    Forma de pagamento
                                    <svg className="hs-accordion-active:hidden hs-accordion-active:group-hover:text-main-30 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg className="hs-accordion-active:block hs-accordion-active:group-hover:text-main-30 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-three" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
                                    <div className="flex flex-col divide-y divide-neutral-20 p-6">
                                        <button type="button" className="flex justify-between w-full bg-white py-4 cursor-pointer">
                                            <span className="text-base font-normal text-neutral-90">Crédito/Débito</span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 21.75C10.6516 21.75 9.38434 21.4936 8.19838 20.9809C7.01239 20.4682 5.98075 19.7724 5.10345 18.8934C4.22615 18.0145 3.53125 16.9826 3.01875 15.7978C2.50625 14.613 2.25 13.3471 2.25 12C2.25 10.6516 2.50636 9.38434 3.01908 8.19838C3.53179 7.01239 4.22763 5.98075 5.10658 5.10345C5.98553 4.22615 7.01739 3.53125 8.20218 3.01875C9.38698 2.50625 10.6529 2.25 12 2.25C13.3484 2.25 14.6157 2.50636 15.8016 3.01908C16.9876 3.53179 18.0192 4.22763 18.8966 5.10658C19.7738 5.98553 20.4688 7.01739 20.9813 8.20217C21.4937 9.38697 21.75 10.6529 21.75 12C21.75 13.3484 21.4936 14.6157 20.9809 15.8016C20.4682 16.9876 19.7724 18.0192 18.8934 18.8966C18.0145 19.7738 16.9826 20.4688 15.7978 20.9813C14.613 21.4937 13.3471 21.75 12 21.75ZM12 19.875C14.1917 19.875 16.0521 19.1104 17.5813 17.5813C19.1104 16.0521 19.875 14.1917 19.875 12C19.875 9.80833 19.1104 7.94792 17.5813 6.41875C16.0521 4.88958 14.1917 4.125 12 4.125C9.80833 4.125 7.94792 4.88958 6.41875 6.41875C4.88958 7.94792 4.125 9.80833 4.125 12C4.125 14.1917 4.88958 16.0521 6.41875 17.5813C7.94792 19.1104 9.80833 19.875 12 19.875Z" fill="#8E8E93" />
                                            </svg>
                                        </button>
                                        <button type="button" className="flex justify-between w-full bg-white py-4 cursor-pointer">
                                            <span className="text-base font-normal text-neutral-90">Pix</span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 21.75C10.6516 21.75 9.38434 21.4936 8.19838 20.9809C7.01239 20.4682 5.98075 19.7724 5.10345 18.8934C4.22615 18.0145 3.53125 16.9826 3.01875 15.7978C2.50625 14.613 2.25 13.3471 2.25 12C2.25 10.6516 2.50636 9.38434 3.01908 8.19838C3.53179 7.01239 4.22763 5.98075 5.10658 5.10345C5.98553 4.22615 7.01739 3.53125 8.20218 3.01875C9.38698 2.50625 10.6529 2.25 12 2.25C13.3484 2.25 14.6157 2.50636 15.8016 3.01908C16.9876 3.53179 18.0192 4.22763 18.8966 5.10658C19.7738 5.98553 20.4688 7.01739 20.9813 8.20217C21.4937 9.38697 21.75 10.6529 21.75 12C21.75 13.3484 21.4936 14.6157 20.9809 15.8016C20.4682 16.9876 19.7724 18.0192 18.8934 18.8966C18.0145 19.7738 16.9826 20.4688 15.7978 20.9813C14.613 21.4937 13.3471 21.75 12 21.75ZM12 19.875C14.1917 19.875 16.0521 19.1104 17.5813 17.5813C19.1104 16.0521 19.875 14.1917 19.875 12C19.875 9.80833 19.1104 7.94792 17.5813 6.41875C16.0521 4.88958 14.1917 4.125 12 4.125C9.80833 4.125 7.94792 4.88958 6.41875 6.41875C4.88958 7.94792 4.125 9.80833 4.125 12C4.125 14.1917 4.88958 16.0521 6.41875 17.5813C7.94792 19.1104 9.80833 19.875 12 19.875Z" fill="#8E8E93" />
                                            </svg>
                                        </button>
                                        <button type="button" className="flex justify-between w-full bg-white py-4 cursor-pointer" data-hs-overlay="#payment-money">
                                            <span className="text-base font-normal text-neutral-90">Dinheiro</span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 16.825C13.3417 16.825 14.4812 16.3562 15.4187 15.4187C16.3562 14.4812 16.825 13.3417 16.825 12C16.825 10.6583 16.3562 9.51875 15.4187 8.58125C14.4812 7.64375 13.3417 7.175 12 7.175C10.6583 7.175 9.51875 7.64375 8.58125 8.58125C7.64375 9.51875 7.175 10.6583 7.175 12C7.175 13.3417 7.64375 14.4812 8.58125 15.4187C9.51875 16.3562 10.6583 16.825 12 16.825ZM12 21.75C10.6516 21.75 9.38434 21.4936 8.19838 20.9809C7.01239 20.4682 5.98075 19.7724 5.10345 18.8934C4.22615 18.0145 3.53125 16.9826 3.01875 15.7978C2.50625 14.613 2.25 13.3471 2.25 12C2.25 10.6516 2.50636 9.38434 3.01908 8.19838C3.53179 7.01239 4.22763 5.98075 5.10658 5.10345C5.98553 4.22615 7.01739 3.53125 8.20218 3.01875C9.38698 2.50625 10.6529 2.25 12 2.25C13.3484 2.25 14.6157 2.50636 15.8016 3.01908C16.9876 3.53179 18.0192 4.22763 18.8966 5.10658C19.7738 5.98553 20.4688 7.01739 20.9813 8.20217C21.4937 9.38697 21.75 10.6529 21.75 12C21.75 13.3484 21.4936 14.6157 20.9809 15.8016C20.4682 16.9876 19.7724 18.0192 18.8934 18.8966C18.0145 19.7738 16.9826 20.4688 15.7978 20.9813C14.613 21.4937 13.3471 21.75 12 21.75ZM12 19.875C14.1917 19.875 16.0521 19.1104 17.5813 17.5813C19.1104 16.0521 19.875 14.1917 19.875 12C19.875 9.80833 19.1104 7.94792 17.5813 6.41875C16.0521 4.88958 14.1917 4.125 12 4.125C9.80833 4.125 7.94792 4.88958 6.41875 6.41875C4.88958 7.94792 4.125 9.80833 4.125 12C4.125 14.1917 4.88958 16.0521 6.41875 17.5813C7.94792 19.1104 9.80833 19.875 12 19.875Z" fill="#D95204" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-start-9 md:col-end-13">
                        <Summary></Summary>
                    </div>
                </div>
            </section>

            <div id="payment-money" className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto">
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-[411px] sm:w-full m-3 sm:mx-auto">
                    <div className="relative flex flex-col bg-white shadow-lg rounded-2xl">
                        <div className="absolute top-2 right-2 z-[10]">
                            <button type="button" className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md bg-white/[.1] text-white hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800" data-hs-overlay="#payment-money">
                                <span className="sr-only">Close</span>
                                <svg className="w-3 h-3" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor" />
                                </svg>
                            </button>
                        </div>

                        <div className="p-10">
                            <h3 className="text-2xl font-semibold text-neutral-90 mb-6">Dinheiro</h3>

                            <h4 className="text-lg font-bold text-neutral-90 mb-4">Precisa de troco?</h4>

                            <div className="flex gap-4 mb-6">
                                <button type="button" className="payment-change flex gap-2 w-fit bg-white py-3 px-4 border border-neutral-20 rounded focus:border-main-30">
                                    <svg className="block hover/payment-change:block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 16.825C13.3417 16.825 14.4812 16.3562 15.4187 15.4187C16.3562 14.4812 16.825 13.3417 16.825 12C16.825 10.6583 16.3562 9.51875 15.4187 8.58125C14.4812 7.64375 13.3417 7.175 12 7.175C10.6583 7.175 9.51875 7.64375 8.58125 8.58125C7.64375 9.51875 7.175 10.6583 7.175 12C7.175 13.3417 7.64375 14.4812 8.58125 15.4187C9.51875 16.3562 10.6583 16.825 12 16.825ZM12 21.75C10.6516 21.75 9.38434 21.4936 8.19838 20.9809C7.01239 20.4682 5.98075 19.7724 5.10345 18.8934C4.22615 18.0145 3.53125 16.9826 3.01875 15.7978C2.50625 14.613 2.25 13.3471 2.25 12C2.25 10.6516 2.50636 9.38434 3.01908 8.19838C3.53179 7.01239 4.22763 5.98075 5.10658 5.10345C5.98553 4.22615 7.01739 3.53125 8.20218 3.01875C9.38698 2.50625 10.6529 2.25 12 2.25C13.3484 2.25 14.6157 2.50636 15.8016 3.01908C16.9876 3.53179 18.0192 4.22763 18.8966 5.10658C19.7738 5.98553 20.4688 7.01739 20.9813 8.20217C21.4937 9.38697 21.75 10.6529 21.75 12C21.75 13.3484 21.4936 14.6157 20.9809 15.8016C20.4682 16.9876 19.7724 18.0192 18.8934 18.8966C18.0145 19.7738 16.9826 20.4688 15.7978 20.9813C14.613 21.4937 13.3471 21.75 12 21.75ZM12 19.875C14.1917 19.875 16.0521 19.1104 17.5813 17.5813C19.1104 16.0521 19.875 14.1917 19.875 12C19.875 9.80833 19.1104 7.94792 17.5813 6.41875C16.0521 4.88958 14.1917 4.125 12 4.125C9.80833 4.125 7.94792 4.88958 6.41875 6.41875C4.88958 7.94792 4.125 9.80833 4.125 12C4.125 14.1917 4.88958 16.0521 6.41875 17.5813C7.94792 19.1104 9.80833 19.875 12 19.875Z" fill="#D95204" />
                                    </svg>

                                    <span className="text-base font-normal text-neutral-90">Sim</span>
                                </button>

                                <button type="button" className="payment-change flex gap-2 w-fit bg-white py-3 px-4 border border-neutral-20 rounded focus:border-main-30">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 19.75C8.65155 19.75 7.38434 19.4936 6.19838 18.9809C5.01239 18.4682 3.98075 17.7724 3.10345 16.8934C2.22615 16.0145 1.53125 14.9826 1.01875 13.7978C0.50625 12.613 0.25 11.3471 0.25 10C0.25 8.65155 0.506358 7.38434 1.01908 6.19838C1.53179 5.01239 2.22763 3.98075 3.10658 3.10345C3.98553 2.22615 5.01739 1.53125 6.20218 1.01875C7.38698 0.50625 8.65292 0.25 10 0.25C11.3484 0.25 12.6157 0.506358 13.8016 1.01908C14.9876 1.53179 16.0192 2.22763 16.8966 3.10658C17.7738 3.98553 18.4688 5.01739 18.9813 6.20217C19.4937 7.38697 19.75 8.65292 19.75 10C19.75 11.3484 19.4936 12.6157 18.9809 13.8016C18.4682 14.9876 17.7724 16.0192 16.8934 16.8966C16.0145 17.7738 14.9826 18.4688 13.7978 18.9813C12.613 19.4937 11.3471 19.75 10 19.75ZM10 17.875C12.1917 17.875 14.0521 17.1104 15.5813 15.5813C17.1104 14.0521 17.875 12.1917 17.875 10C17.875 7.80833 17.1104 5.94792 15.5813 4.41875C14.0521 2.88958 12.1917 2.125 10 2.125C7.80833 2.125 5.94792 2.88958 4.41875 4.41875C2.88958 5.94792 2.125 7.80833 2.125 10C2.125 12.1917 2.88958 14.0521 4.41875 15.5813C5.94792 17.1104 7.80833 17.875 10 17.875Z" fill="#8E8E93" />
                                    </svg>
                                    <span className="text-base font-normal text-neutral-90">Não</span>
                                </button>
                            </div>

                            <div className="relative z-0 w-full group grid gap-1 mb-6">
                                <label htmlFor="valor" className="relative text-xs font-semibold text-neutral-50">Valor</label>
                                <input type="text" name="valor" id="valor"
                                    className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                    placeholder="R$ 100,00" required />
                            </div>

                            <button type="button" className="py-3 px-4 inline-flex w-fit justify-center items-center rounded border border-transparent text-sm font-bold bg-main-30 text-white focus:outline-none transition-all" data-hs-overlay="#payment-money">
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Cart;