// import { useCallback } from 'react';

import { useProductsContext } from 'views/ShoppingCart/contexts/product/ProductProvider';
// import { Product } from 'views/StoreDetail/types';

const useProducts = () => {
  const {
    isFetching,
    setIsFetching,
    products,
    setProducts,
    filters,
    setFilters,
  } = useProductsContext();

  // const fetchProducts = useCallback(() => {
  //   setIsFetching(true);
  //   getProducts().then((products: Product[]) => {
  //     setIsFetching(false);
  //     setProducts(products);
  //   });
  // }, [setIsFetching, setProducts]);

  // TODO implement dynamic filter products
  const filterProducts = (filters: string[]) => {
    // setIsFetching(true);
    // getProducts().then((products: Product[]) => {
    //   setIsFetching(false);
    //   let filteredProducts;

      // TODO customize this filter getting current filter
      // if (filters && filters.length > 0) {
      //   filteredProducts = products.filter((p: Product) =>
      //     filters.find((filter: string) =>
      //       p.availableSizes.find((size: string) => size === filter)
      //     )
      //   );
      // } else {
        // filteredProducts = products;
      // }

      // setFilters(filters);
      // setProducts(filteredProducts);
    // });
  };

  return {
    // fetchProducts,
    setIsFetching,
    isFetching,
    products,
    filterProducts,
    filters,
    setFilters,
    setProducts
  };
};

export default useProducts;
