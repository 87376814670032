import 'preline';
import { useState } from 'react';
import ChangeModal from './components/ChangeModal';
import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSetup } from '../../../Home/hooks/useSetup';
import { useEffect } from 'react';

export type FormType = {
    name?: string;
    email?: string;
    phone?: string;
    address?: string;
    zipCode?: string;
    number?: string;
    neighborhood?: string;
    city?: string;
    complement?: string;
    paymentType?: string;
    needsChange?: string;
    changeValue?: string;
};

const checkedSvgPath = "M12 16.825C13.3417 16.825 14.4812 16.3562 15.4187 15.4187C16.3562 14.4812 16.825 13.3417 16.825 12C16.825 10.6583 16.3562 9.51875 15.4187 8.58125C14.4812 7.64375 13.3417 7.175 12 7.175C10.6583 7.175 9.51875 7.64375 8.58125 8.58125C7.64375 9.51875 7.175 10.6583 7.175 12C7.175 13.3417 7.64375 14.4812 8.58125 15.4187C9.51875 16.3562 10.6583 16.825 12 16.825ZM12 21.75C10.6516 21.75 9.38434 21.4936 8.19838 20.9809C7.01239 20.4682 5.98075 19.7724 5.10345 18.8934C4.22615 18.0145 3.53125 16.9826 3.01875 15.7978C2.50625 14.613 2.25 13.3471 2.25 12C2.25 10.6516 2.50636 9.38434 3.01908 8.19838C3.53179 7.01239 4.22763 5.98075 5.10658 5.10345C5.98553 4.22615 7.01739 3.53125 8.20218 3.01875C9.38698 2.50625 10.6529 2.25 12 2.25C13.3484 2.25 14.6157 2.50636 15.8016 3.01908C16.9876 3.53179 18.0192 4.22763 18.8966 5.10658C19.7738 5.98553 20.4688 7.01739 20.9813 8.20217C21.4937 9.38697 21.75 10.6529 21.75 12C21.75 13.3484 21.4936 14.6157 20.9809 15.8016C20.4682 16.9876 19.7724 18.0192 18.8934 18.8966C18.0145 19.7738 16.9826 20.4688 15.7978 20.9813C14.613 21.4937 13.3471 21.75 12 21.75ZM12 19.875C14.1917 19.875 16.0521 19.1104 17.5813 17.5813C19.1104 16.0521 19.875 14.1917 19.875 12C19.875 9.80833 19.1104 7.94792 17.5813 6.41875C16.0521 4.88958 14.1917 4.125 12 4.125C9.80833 4.125 7.94792 4.88958 6.41875 6.41875C4.88958 7.94792 4.125 9.80833 4.125 12C4.125 14.1917 4.88958 16.0521 6.41875 17.5813C7.94792 19.1104 9.80833 19.875 12 19.875Z";
const uncheckedSVGPath = "M12 21.75C10.6516 21.75 9.38434 21.4936 8.19838 20.9809C7.01239 20.4682 5.98075 19.7724 5.10345 18.8934C4.22615 18.0145 3.53125 16.9826 3.01875 15.7978C2.50625 14.613 2.25 13.3471 2.25 12C2.25 10.6516 2.50636 9.38434 3.01908 8.19838C3.53179 7.01239 4.22763 5.98075 5.10658 5.10345C5.98553 4.22615 7.01739 3.53125 8.20218 3.01875C9.38698 2.50625 10.6529 2.25 12 2.25C13.3484 2.25 14.6157 2.50636 15.8016 3.01908C16.9876 3.53179 18.0192 4.22763 18.8966 5.10658C19.7738 5.98553 20.4688 7.01739 20.9813 8.20217C21.4937 9.38697 21.75 10.6529 21.75 12C21.75 13.3484 21.4936 14.6157 20.9809 15.8016C20.4682 16.9876 19.7724 18.0192 18.8934 18.8966C18.0145 19.7738 16.9826 20.4688 15.7978 20.9813C14.613 21.4937 13.3471 21.75 12 21.75ZM12 19.875C14.1917 19.875 16.0521 19.1104 17.5813 17.5813C19.1104 16.0521 19.875 14.1917 19.875 12C19.875 9.80833 19.1104 7.94792 17.5813 6.41875C16.0521 4.88958 14.1917 4.125 12 4.125C9.80833 4.125 7.94792 4.88958 6.41875 6.41875C4.88958 7.94792 4.125 9.80833 4.125 12C4.125 14.1917 4.88958 16.0521 6.41875 17.5813C7.94792 19.1104 9.80833 19.875 12 19.875Z";
const checkedColor = '#D95204';
const unCheckedColor = '#8E8E93';

export const defaultPaymentValues = {
    defaultValues: {
        name: '',
        email: '',
        phone: '',
        address: '',
        zipCode: '',
        number: '',
        neighborhood: '',
        city: '',
        complement: '',
        paymentType: 'Crédito/Débito',
        needsChange: '',
        changeValue: ''
    }
};

export const paymentValidationSchema = yup.object().shape({
    name: yup.string().required('*Campo Obrigatório'),
    email: yup.string().required('*Campo Obrigatório').email('*Campo E-mail inválido'),
    phone: yup.string().required('*Campo Obrigatório'),
    zipCode: yup.string().required('*Campo Obrigatório'),
    address: yup.string().required('*Campo Obrigatório'),
    number: yup.string().required('*Campo Obrigatório'),
    complement: yup.string().required('*Campo Obrigatório'),
    neighborhood: yup.string().required('*Campo Obrigatório'),
    city: yup.string().required('*Campo Obrigatório'),
    paymentType: yup.string().required('*Campo Obrigatório'),
}).required();

const PaymentForm = () => {
    const { register, setValue, formState } = useFormContext();
    const [paymentTypeValue, setPaymentType] = useState('Crédito/Débito');
    const { data: setupData } = useSetup();

    useEffect(() => {
        if (setupData && setupData.setup_address) {
            setValue('zipCode', setupData.setup_address.cep);
            setValue('address', setupData.setup_address.street);
            setValue('number', setupData.setup_address.number);
            setValue('complement', setupData.setup_address.complement);
            setValue('neighborhood', setupData.setup_address.neighborhood);
            setValue('city', setupData.setup_address.city);
        }
    }, [setupData, setValue]);

    const handlePaymentType = (type: string) => () => {
        setPaymentType(type);
        setValue('paymentType', type);
    };

    const isMoney = paymentTypeValue === 'Dinheiro';
    const isPix = paymentTypeValue === 'Pix';
    const isCreditCard = paymentTypeValue === 'Crédito/Débito';

    const handleChangeValue = ({ needsChange, changeValue }: { needsChange: string; changeValue: string; }) => {
        setValue('needsChange', needsChange);
        setValue('changeValue', changeValue);
    };

    console.log('errors ==>', formState.errors);

    return (
    <div className="hs-accordion-group grid gap-8" data-hs-accordion-always-open>
            {/* User Info section */}
            <div className="hs-accordion active rounded-2xl overflow-hidden border border-neutral-20" id="hs-basic-with-title-and-arrow-stretched-heading-one">
                <button className="hs-accordion-toggle bg-neutral-10 group py-4 px-6 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-black text-xl transition" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                    Dados pessoais
                    <svg className="hs-accordion-active:hidden hs-accordion-active:group-hover:text-main-30 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    <svg className="hs-accordion-active:block hs-accordion-active:group-hover:text-main-30 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-one" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
                    <div className="grid gap-4 p-6" id="contact-form">
                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                        <label htmlFor="nome" className="relative text-xs font-semibold text-neutral-50">Nome</label>
                        <input
                            type="text"
                            id="name"
                            {...register("name")}
                            className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                            placeholder="Livia da Silva"
                        />
                        {formState?.errors?.name?.message ? (
                            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                {formState?.errors?.name?.message as string}
                            </span>
                        ) : null}
                        </div>
                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                        <label htmlFor="email" className="relative text-xs font-semibold text-neutral-50">E-mail</label>
                        <input type="email" id="email"
                            {...register("email")}
                            className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                            placeholder="liviadasilva@email.com" />
                            {formState?.errors?.email?.message ? (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {formState?.errors?.email?.message as string}
                                    </span>
                                ) : null}
                        </div>
                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                        <label htmlFor="telefone" className="relative text-xs font-semibold text-neutral-50">Telefone</label>
                        <InputMask
                            mask={'(99)9 9999-9999'}
                            id="telefone"
                            {...register("phone")}
                            className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                            placeholder="(84)98888-0000"
                        />
                            {formState?.errors?.phone?.message ? (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {formState?.errors?.phone?.message as string}
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            {/* Address section */}
            <div className="hs-accordion active rounded-2xl overflow-hidden border border-neutral-20" id="hs-basic-with-title-and-arrow-stretched-heading-two">
                <button className="hs-accordion-toggle bg-neutral-10 group py-4 px-6 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-black text-xl transition" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
                    Endereço
                    <svg className="hs-accordion-active:hidden hs-accordion-active:group-hover:text-main-30 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    <svg className="hs-accordion-active:block hs-accordion-active:group-hover:text-main-30 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-two" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
                    <div className="grid gap-4 p-6" id="address-form">
                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                            <label htmlFor="zipCode" className="relative text-xs font-semibold text-neutral-50">CEP</label>
                            <InputMask
                                mask="99.999-999"
                                readOnly
                                type="text" 
                                {...register('zipCode')}
                                value={setupData?.setup_address.cep}
                                id="zipCode"
                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                placeholder="59.115-215"
                            />
                            {formState?.errors?.zipCode?.message ? (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {formState?.errors?.zipCode?.message as string}
                                </span>
                            ) : null}
                        </div>
                        <div className="flex gap-4">
                            <div className="relative z-0 w-full group grid gap-1 mb-4">
                                <label htmlFor="address" className="relative text-xs font-semibold text-neutral-50">Rua/Avenida</label>
                                <input type="text" id="address"
                                    {...register('address')}
                                    value={setupData?.setup_address.street}
                                    readOnly
                                    className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                    placeholder="Av Ayrton Senna" />
                                {formState?.errors?.address?.message ? (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {formState?.errors?.address?.message as string}
                                    </span>
                                ) : null}
                            </div>
                            <div className="relative z-0 w-30 group grid gap-1 mb-4">
                                <label htmlFor="number" className="relative text-xs font-semibold text-neutral-50">Número</label>
                                <input type="text" id="number" {...register('number')}
                                    value={setupData?.setup_address.number}
                                    readOnly
                                    className="block w-full py-2 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                    placeholder="Número" />
                                {formState?.errors?.number?.message ? (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {formState?.errors?.number?.message as string}
                                    </span>
                                ) : null}
                            </div>
                            <div className="relative z-0 w-full group grid gap-1 mb-4">
                                <label htmlFor="complement" className="relative text-xs font-semibold text-neutral-50">Complemento</label>
                                <input type="text" id="complement" {...register('complement')}
                                    className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                    placeholder="" />
                                
                                {formState?.errors?.complement?.message ? (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {formState?.errors?.complement?.message as string}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                            <label htmlFor="city" className="relative text-xs font-semibold text-neutral-50">Cidade</label>
                            <input type="text" id="city" {...register('city')} readOnly
                                value={setupData?.setup_address.city}
                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                placeholder="Natal" />
                            {formState?.errors?.city?.message ? (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {formState?.errors?.city?.message as string}
                                </span>
                            ) : null}
                        </div>
                        <div className="relative z-0 w-full group grid gap-1 mb-4">
                            <label htmlFor="neighborhood" className="relative text-xs font-semibold text-neutral-50">Bairro</label>
                            <input
                                type="text" 
                                id="neighborhood" {...register('neighborhood')}
                                readOnly
                                value={setupData?.setup_address.neighborhood}
                                className="block w-full py-3 px-1 text-base font-normal text-neutral-90 placeholder-neutral-50 bg-transparent border-b border-b-neutral-20 appearance-none focus:outline-none focus:ring-0 focus:border-main-20"
                                placeholder="Cidade Jardim" />
                            {formState?.errors?.neighborhood?.message ? (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {formState?.errors?.neighborhood?.message as string}
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            {/* Payment section */}
            <div className="hs-accordion active rounded-2xl overflow-hidden border border-neutral-20" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                <button className="hs-accordion-toggle bg-neutral-10 group py-4 px-6 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-black text-xl transition" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
                    Forma de pagamento
                    <svg className="hs-accordion-active:hidden hs-accordion-active:group-hover:text-main-30 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    <svg className="hs-accordion-active:block hs-accordion-active:group-hover:text-main-30 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-three" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
                <div className="flex flex-col divide-y divide-neutral-20 p-6">
                    <button type="button" onClick={handlePaymentType('Crédito/Débito')} className="flex justify-between w-full bg-white py-4 cursor-pointer">
                        <span className="text-base font-normal text-neutral-90">Crédito/Débito</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d={isCreditCard ? checkedSvgPath : uncheckedSVGPath} fill={isCreditCard ? checkedColor : unCheckedColor} />
                        </svg>
                    </button>
                    <button onClick={handlePaymentType('Pix')} type="button" className="flex justify-between w-full bg-white py-4 cursor-pointer">
                        <span className="text-base font-normal text-neutral-90">Pix</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d={isPix ? checkedSvgPath : uncheckedSVGPath} fill={isPix ? checkedColor : unCheckedColor} />
                        </svg>
                    </button>
                    <button onClick={handlePaymentType('Dinheiro')} type="button" className="flex justify-between w-full bg-white py-4 cursor-pointer" data-hs-overlay="#payment-money">
                        <span className="text-base font-normal text-neutral-90">Dinheiro</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d={isMoney ? checkedSvgPath : uncheckedSVGPath} fill={isMoney ? checkedColor : unCheckedColor} />
                        </svg>
                    </button>
                </div>
                </div>
            </div>
        <ChangeModal afterSubmit={handleChangeValue} />
    </div>
  );
};

export default PaymentForm;
