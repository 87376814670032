import {
  RouterProvider,
} from 'react-router-dom';

import { BaseRoutes } from './routes/Root';
import ColorSetter from './components/ColorSetter';

/* Context Providers */
import { ProductsProvider } from 'views/ShoppingCart/contexts/product';
import { CartProvider } from 'views/ShoppingCart/contexts/cart';

function App() {
  return (
    <CartProvider>
      <ProductsProvider>
        <>
          <ColorSetter />
          <RouterProvider router={BaseRoutes} />
        </>
      </ProductsProvider>
    </CartProvider>
  );
}

export default App;
