import { createContext, useContext, useState } from 'react';

import { Product } from 'views/StoreDetail/types';

export type ProductsContextType = {
  isFetching: boolean;
  setIsFetching(state: boolean): void;
  products: Product[];
  setProducts(products: Product[]): void;
  filters: string[];
  setFilters(filters: string[]): void;
}

const ProductsContext = createContext<ProductsContextType | undefined>(undefined);
const useProductsContext = (): ProductsContextType => {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error(
      'useProductsContext must be used within a ProductsProvider'
    );
  }

  return context;
};

const ProductsProvider = (props: any) => {
  const [isFetching, setIsFetching] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [filters, setFilters] = useState<string[]>([]);

  const ProductContextValue: ProductsContextType = {
    isFetching,
    setIsFetching,
    products,
    setProducts,
    filters,
    setFilters,
  };

  return <ProductsContext.Provider value={ProductContextValue} {...props} />;
};

export { ProductsProvider, useProductsContext };
