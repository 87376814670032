import Cart from 'views/ShoppingCart/components/Cart';

const ShoppingCart = () => {
  return (
    <div>
      <Cart />
    </div>
  );
};

export default ShoppingCart;
