import { useCallback } from 'react';
import fetch from 'services/fetch';
import { useCartContext } from '../../contexts/cart/CartProvider';
import { useCartProducts } from './useCartProducts';
import useCartTotal from './useCartTotal';
import { buildServiceUrl } from 'utils';

const MUTATE_ORDER_KEY = buildServiceUrl('create-order/');

export const defaultResources = {
  add: MUTATE_ORDER_KEY,
};

const useCart = () => {
  const { isOpen, setIsOpen, setStoreId, storeId } = useCartContext();
  const {
    products,
    addProduct,
    updateQuantitySafely,
    removeProductFromCart,
    // TODO
    // removeProduct,
    // increaseProductQuantity,
    decreaseProductQuantity,
    clearCart,
  } = useCartProducts();
  const { total, updateCartTotal } = useCartTotal();

  const openCart = () => setIsOpen(true);
  const closeCart = () => setIsOpen(false);

  const submitOrder = useCallback((data: any) => {
      return fetch.post(`${defaultResources.add}`, data);
    },
    []
  );

  return {
    submitOrder,
    isOpen,
    openCart,
    closeCart,
    products,
    addProduct,
    removeProductFromCart,
    // increaseProductQuantity,
    decreaseProductQuantity,
    total,
    updateQuantitySafely,
    updateCartTotal,
    clearCart,
    setStoreId,
    storeId
  };
};

export default useCart;