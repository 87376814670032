import { useState, useEffect } from 'react';
import fetch from 'services/fetch';

export const defaultResources = {
  get: `https://adm-minuteprices.srvspeed.com/v1/stores`,
};

export function useFeatured(ids?: any[]) {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ids && !data.length) {
      (async() => {
        try {
          setLoading(true);
          const data = ids.map((id: number) => {
            return fetch.get(`${defaultResources.get}/${id}`);
          });
          const result = await Promise.all(data);
          setLoading(false);
          setData(result.map(response => response?.data?.results?.[0]));
        } catch(e) {
          setLoading(false);
          console.log('error featured', e);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids?.length, data]);

  return {
    loading,
    stores: data,
  };
}
