import { Link } from 'react-router-dom';
import { CategoryNavItemType } from './CategoryNavItem.type';

const CategoryNavItem = ({
  categoryTitle,
  categoryId,
  categoryIcon,
}: CategoryNavItemType) => {
  const navigateTo = `?category=${categoryTitle}`;
  return (
    <Link to={navigateTo} className="bg-neutral-10 md:bg-neutral-0 p-3 rounded flex flex-col items-center justify-center gap-4 hover:bg-neutral-10 transition-all duration-200 ease-in-out w-[104px]">
      <div className="w-12 h-12">
        <img src={categoryIcon} alt={categoryTitle} />
      </div>
      <span className="text-xs font-semibold text-neutral-90">{categoryTitle}</span>
    </Link>
  );
};

export default CategoryNavItem;
