import { useEffect } from 'react';
import fetch, { useCustomSWR } from 'services/fetch';
import { Store } from 'views/StoreDetail/types';
import { buildServiceUrl } from 'utils';
import { Product, Catalog } from 'views/StoreDetail/types';
import useProducts from 'views/ShoppingCart/hooks/product/useProducts';

const MUTATE_STORES_KEY = buildServiceUrl('stores');

export const defaultResources = {
  getAll: `${MUTATE_STORES_KEY}`,
};

export function useStore(id?: string | number): {
  data: Store;
  isValidating: boolean;
  loading: boolean;
  error?: unknown;
  mutate: any;
} {
  const { products, setProducts } = useProducts();

  const { data: response, error, isValidating, mutate } = useCustomSWR<any>(
    id ? `${defaultResources.getAll}/${id}`: null,
    (url: string) => fetch.get(`${url}`)
  );

  const catalog = response?.data?.results?.[0];

  useEffect(() => {
      if (catalog?.store_catalog && !products.length) {
      const allProducts = catalog?.store_catalog?.reduce((acc: Product[], p: Catalog) => {
        acc = [...acc, ...p.items] as Product[];
        return acc;
      }, [] as Product[]);
      setProducts(allProducts);
    }
  },
    [catalog?.store_catalog, products.length, setProducts]
  );

  return {
    data: response?.data?.results?.[0],
    mutate,
    isValidating,
    loading: !response?.data?.results,
    error,
  };
}
