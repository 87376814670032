import { useCategory } from './hooks/useCategory';
import { Swiper, SwiperSlide } from 'swiper/react';
import CategoryNavItem from './components/CategoryNavItem';

const FilterCategory = () => {
  const { categoriesData } = useCategory();

  return (
    <div className="container px-4 lg:px-6 xxl:px-0 grid gap-3">
      {(categoriesData || []).length ? (
        <Swiper
          spaceBetween={12}
          slidesPerView={2}
          onSlideChange={() => console.log('slide change')}
          className="inline-block w-full"
        >
          {
            (categoriesData || []).map(
              (c) => {
                return (
                  <SwiperSlide key={c.cat_id} className="max-w-[104px]">
                    <CategoryNavItem
                      categoryTitle={c.cat_title}
                      categoryId={c.cat_id}
                      categoryIcon={c.cat_icon}
                    />
                  </SwiperSlide>
                );
              }
            )
          }
        </Swiper>
      ) : 'Carregando...'}
    </div>
  );
};

export default FilterCategory;
