import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useCompany } from './hooks/useCompany';
import ListCompanies from './components/ListCompanies';
import { CompanyType } from 'views/Home/components/Company/Company.type';

const Company = () => {
  const [category, setCategory] = useState<string | undefined | null>();
  const [pageNumber, setPagination] = useState<number>(1); 
  const location = useLocation();
  let navigate = useNavigate();

  let [searchParams] = useSearchParams();

  useEffect(() => {
    setCategory(searchParams?.get('category'));
  }, [location?.search, searchParams]);

  // const { data, companiesCount, limitPerPage, loading } = useCompany(category, pageNumber);
  const { data, loading } = useCompany(category, pageNumber);

  const loadMore = () => {
    setPagination((currentPage: number) => currentPage + 1);
    navigate(`?category=${category ?? 'Restaurante'}&page=${pageNumber}`);
  };

  let dataList: CompanyType[] = data;

  if (category && data) {
    dataList = data.filter((d: CompanyType) => d.store_category_title === category);
  }

  return (
    <section className="s-establishments">
      <div className="container px-4 lg:px-6 xxl:px-0 grid gap-4 md:gap-6">
        <h2 className="text-xl font-semibold text-black">Empresas</h2>
        {loading 
          ? 'Carregando...'
          : <ListCompanies companies={dataList} loadMore={loadMore} />}
      </div>
    </section>
  );
};

export default Company;
