import { useCartContext } from '../../contexts/cart/CartProvider';
import { Product } from '../../../StoreDetail/types';

const useCartTotal = () => {
  const { total, setTotal } = useCartContext();

  const updateCartTotal = (products: Product[]) => {
    const productQuantity = products.reduce(
      (sum: number, product: Product) => {
        sum += product?.quantity ?? 0;
        return sum;
      },
      0
    );

    const totalPrice = products.reduce((sum: number, product: Product) => {
      const { with_discount, without_discount } = product?.value;
      const formattedPrice = Number.parseFloat(
        (with_discount ? with_discount : without_discount).replaceAll(',', '.') ?? 0
      );
      sum += formattedPrice * (product?.quantity ?? 0);
      return sum;
    }, 0);

    const total = {
      productQuantity,
      totalPrice,
      currencyId: 'BLR',
      currencyFormat: 'R$',
    };

    setTotal(total);
  };

  return {
    total,
    updateCartTotal,
  };
};

export default useCartTotal;