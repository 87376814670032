import {
  createBrowserRouter,
} from 'react-router-dom';

import Home from 'views/Home';
import Search from 'views/Search';
import StoreDetail from '../views/StoreDetail';
import ShoppingCart from '../views/ShoppingCart';

// Static pages
import StaticHome from '../views/Static/Home';
import StaticRestaurant from 'views/Static/Restaurant';
import StaticCart from 'views/Static/Cart';
import StaticService from 'views/Static/Service';
import Success from 'views/ShoppingCart/components/Success';
import StaticSearch from 'views/Static/Search';

export const BaseRoutes = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/search',
    element: <Search />,
  },
  {
    path: '/restaurante/:id',
    element: <StoreDetail />,
  },
  {
    path: '/carrinho-de-compras/:id',
    element: <ShoppingCart />,
  },
  {
    path: '/static/home',
    element: <StaticHome />,
  },
  {
    path: '/static/restaurant',
    element: <StaticRestaurant />,
  },
  {
    path: '/static/service',
    element: <StaticService />,
  },
  {
    path: '/static/cart',
    element: <StaticCart />,
  },
  {
    path: 'success/:id',
    element: <Success />,
  },
  {
    path: '/static/search',
    element: <StaticSearch />,
  },
]);
