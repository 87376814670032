import fetch, { useCustomSWR } from 'services/fetch';

export const defaultResources = {
  get: `https://adm-minuteprices.srvspeed.com/v1/setup/${process.env.REACT_APP_MINUTE_PRICES_API_SETUP_ID}`,
};

type Response = {
  banners: [];
  featured_stores: [];
  main_colors: any;
  request_id: number;
  setup_name: string;
  unit_id: number;
  unit_logo_size: number;
  unit_logo_url: string;
  unit_name: string;
  setup_address: {
    cep?: number | string;
    city?: string;
    complement?: string;
    neighborhood?: string;
    number?: number | string;
    street?: number | string; 
  };
}

export function useSetup(): {
  data: Response;
  isValidating: boolean;
  loading: boolean;
  error?: unknown;
} {
  const { data: response, error, isValidating } = useCustomSWR<any>(
    defaultResources.get,
    (url: string) => fetch.get(`${url}`)
  );

  return {
    data: response?.data || [],
    isValidating,
    loading: !response?.data,
    error,
  };
}
