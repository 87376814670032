import ListHighlighted from './components/ListHighlighted';
import { useSetup } from '../../hooks/useSetup';
import { useFeatured }  from '../../hooks/useFeatured';

const Highlighted = () => {
  const { data } = useSetup();
  const { stores } = useFeatured(data?.featured_stores);
  return (
    <div className="category-list">
      <section className="s-featured bg-main-30 py-8">
        <div className="container px-4 lg:px-6 xxl:px-0 flex flex-col gap-4 md:gap-6">
          <h2 className="text-xl font-semibold text-white">Em destaque</h2>
          <div className="merchant-list flex flex-col md:grid md:grid-cols-3 gap-4 md:gap-6">
            <ListHighlighted stores={stores} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Highlighted;
