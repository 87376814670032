import fetch, { useCustomSWR } from 'services/fetch';

// https://adm-minuteprices.srvspeed.com/v1/search?type=stores&q=digital
// https://adm-minuteprices.srvspeed.com/v1/search?type=items&q=a
export const defaultResources = {
  get: 'https://adm-minuteprices.srvspeed.com/v1/search',
};

export const useSearch = (filterType = 'stores', searchTerm = ''): {
  data: any[];
  isValidating: boolean;
  loading: boolean;
  error?: unknown;
  mutate: any;
  isEmpty: boolean;
} => {
  const { data: response, error, isValidating, mutate } = useCustomSWR<any>(
    filterType && searchTerm ? `${defaultResources.get}?type=${filterType}&q=${searchTerm}` : defaultResources.get,
    (url: string) => fetch.get(`${url}`)
  );


  return {
    data: response?.data?.results || [],
    mutate,
    isEmpty: response?.data?.results?.length === 0,
    isValidating,
    loading: !response?.data?.results,
    error,
  };
}
