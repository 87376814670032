import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import fetch, { useCustomSWR } from 'services/fetch';
import { CategoryType } from 'views/Home/components/FilterCategory/FilterCategory.type';
import { buildServiceUrl } from 'utils';

const MUTATE_CATEGORIES_KEY = buildServiceUrl('categories/');

export const defaultResources = {
  add: 'create',
  update: 'edit',
  remove: 'delete',
  getAll: `${MUTATE_CATEGORIES_KEY}all`,
  getOne: 'category',
};

export function useCategory(): {
  categoriesData: CategoryType[];
  isValidating: boolean;
  loading: boolean;
  error?: unknown;
  mutate: any;
  post: (data: {}) => Promise<AxiosResponse<any>>;
  update: (data: {}) => Promise<AxiosResponse<any>>;
  remove: (categoryId: string) => Promise<AxiosResponse<any>>;
  getOne: (categoryId: string | number) => Promise<AxiosResponse<any>>;
} {
  const { data: response, error, isValidating, mutate } = useCustomSWR<any>(
    defaultResources.getAll,
    (url: string) => fetch.get(`${url}`)
  );

  const post = useCallback(
    async (data: {}) => {
      const response = await fetch.post(`${defaultResources.add}`, data);
      mutate();
      return response.data;
    },
    [mutate]
  );

  const update = useCallback(
    async (data: {}) => {
      const response = await fetch.patch(`${defaultResources.update}`, data);
      mutate();

      return response.data;
    },
    [mutate]
  );

  const remove = useCallback(
    async (categoryId: string) => {
      const response = await fetch.post(`${defaultResources.remove}`, {
        id: categoryId,
      });
      mutate();
      return response.data;
    },
    [mutate]
  );

  const getOne = useCallback(
    async (id: string | number) => {
      const response = await fetch.get(`${defaultResources.getOne}/${id}`);
      mutate();

      return response.data;
    },
    [mutate]
  );

  return {
    categoriesData: response?.data || [],
    mutate,
    isValidating,
    loading: !response?.data,
    error,
    post,
    update,
    remove,
    getOne,
  };
}
