import { createContext, useContext, useState } from 'react';
import { ProductType, CartTotalType } from 'views/ShoppingCart/types'

export type CartContextType = {
  isOpen: boolean;
  setIsOpen(state: boolean): void;
  products: ProductType[];
  setProducts(products: ProductType[]): void;
  total: CartTotalType;
  setTotal(products: any): void;
  storeId: number | string | undefined;
  setStoreId: (id: number | string | undefined) => void;
};

const CartContext = createContext<CartContextType | undefined>(undefined);

const useCartContext = (): CartContextType => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCartContext must be used within a CartProvider');
  }

  return context;
};

const totalInitialValues = {
  productQuantity: 0,
  installments: 0,
  totalPrice: 0,
  currencyId: 'BRL',
  currencyFormat: '$',
};

const CartProvider = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [total, setTotal] = useState<CartTotalType>(totalInitialValues);
  const [storeId, setStoreId] = useState<number | undefined | string>();

  const CartContextValue: CartContextType = {
    isOpen,
    setIsOpen,
    products,
    setProducts,
    total,
    setTotal,
    setStoreId,
    storeId
  };

  return <CartContext.Provider value={CartContextValue} {...props} />;
};

export { CartProvider, useCartContext };