import { Link } from "react-router-dom";

const empty = false;
const summaryItensRepeat = 2;
const isCart = window.location.pathname === '/static/cart';
const isService = window.location.pathname === '/static/service';
const type = isService ? 'service' : 'product';
const queryParams = new URLSearchParams(window.location.search);

const Summary = () => {
    return (
        <>
            {empty ? (
                // Renderizar algo quando 'empty' for verdadeiro
                <div className="rounded-2xl border-neutral-20 border py-16 px-10 flex flex-col items-center justify-center gap-6 sticky top-4">
                    <img src="https://liveprint.com.br/content/uploads/2023/09/empty-cart.svg" alt="Empty" />
                    <p className="text-lg font-normal text-neutral-50 text-center">Toque em um produto para adicioná-lo ao carrinho</p>
                </div>
            ) : (
                // Renderizar algo quando 'empty' for falso
                <div className="rounded-2xl border-neutral-20 border py-6 px-4 flex flex-col sticky top-4">
                    {isCart ? (
                        <header className="left flex items-center justify-start gap-4 pb-6 border-b-2 border-b-neutral-20">
                            <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                                { queryParams.get('type') === 'service' ? (
                                    <img src="https://s3-alpha-sig.figma.com/img/6552/cdba/8ad89eebf932d04383ac4f3386dca999?Expires=1697414400&Signature=TFQSJt7Ao5v2SWKYNXwuNE5~ZSteKH86xx1AfItk2wU-M0bcK3hJBeSDQsNdoLsS9X2G4JLqS2fOF99t2~rson8wtzcHihgTXJe0Nrfu6CVCJkylJmMYwdfpvNyvnolHA8KbT9Pb6iGTkXYJcK8wnZh~FFo~z713RWx3eK3ZA7uIXbxWbWJPmdiahjpV-kYZOu8AfoVEeayx~LLe0vtPyLwxGcZrsiz0ROga2zOr3ssgrVMt5H6bZcCPwZ4pVc~rOg7D2NeloazSVkRwhCYNvKNyuYvYMEb7CmlWJfpJIxytB6fOQbLrj~vMzYXnkgh~SCRUC6RPa3wcnO8QhZrO8Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" alt="" className="w-full h-full object-cover object-center" />
                                ) : (
                                    <img src="https://s3-alpha-sig.figma.com/img/1284/b16f/4639b25013e597b8b524c4382851a37e?Expires=1696809600&Signature=LdE-tE5k~RC7lAUVg9jrboOufxdlecYjtZt2fGm0Gg9QtBHNI91DoXRpqkINkOKgYIBcd2mr1xETDIptTyxVWudyBFt3otQTc2lxntjxrzzIGhPT2tqcEOn6h-GZcFXcMAnkclC4gHA3l0CzeblU~zkjAKA4PqxOxV6~xSUMDR6O~522yYTi8LlShQJKDwYOWYhEmTIrBm7s6zF1~GKA4ix~B08KF2YbkEyPOYw5KXbq4wVGjcVW~ztC7wK2f97FZcCbKEHvfbGsOAsl-w8te8R0J8nvw-4-F8F3FtyCb0EQRw-BrEG-PcVDYI3GMYQxGrRhVRz~rDmsxc-aDQJPIw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" alt="" className="w-full h-full object-cover object-center" />
                                )}
                            </div>

                            <div className="info flex flex-col items-start justify-center gap-0">
                                <span className="text-xs font-semibold text-neutral-60">Seu carrinho</span>
                                <h3 className="text-base font-bold text-neutral-90 flex gap-1 items-center justify-center">
                                    { queryParams.get('type') === 'service' ? 'Time to Shave' : 'Rio the Un-bar '}
                                    <i className="cursor-pointer transition-all duration-200 ease-in-out hover:translate-x-1"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4999 9.99996L7.24992 6.74996C7.09714 6.59718 7.02075 6.40274 7.02075 6.16663C7.02075 5.93051 7.09714 5.73607 7.24992 5.58329C7.4027 5.43051 7.59714 5.35413 7.83325 5.35413C8.06936 5.35413 8.26381 5.43051 8.41659 5.58329L12.2499 9.41663C12.3333 9.49996 12.3923 9.59024 12.427 9.68746C12.4617 9.78468 12.4791 9.88885 12.4791 9.99996C12.4791 10.1111 12.4617 10.2152 12.427 10.3125C12.3923 10.4097 12.3333 10.5 12.2499 10.5833L8.41659 14.4166C8.26381 14.5694 8.06936 14.6458 7.83325 14.6458C7.59714 14.6458 7.4027 14.5694 7.24992 14.4166C7.09714 14.2638 7.02075 14.0694 7.02075 13.8333C7.02075 13.5972 7.09714 13.4027 7.24992 13.25L10.4999 9.99996Z" fill="#1C1C1E" /></svg></i>
                                </h3>
                            </div>
                        </header>
                    ) : (
                        <Link className="go-to-cart py-3 px-4 inline-flex justify-between items-center gap-2 mb-6 rounded border border-transparent text-sm font-bold bg-main-30 text-white w-full hover:bg-main-20 transition-all duration-200 ease-in-out disabled:text-neutral-20" to={`/static/cart?type=${type}`}>
                            Ir para o carrinho
                            <span className="total-price">R$ 57,70</span>
                        </Link>
                    )}

                    <ul className="flex flex-col gap-2 divide-y divide-neutral-20">
                        {Array.from({ length: summaryItensRepeat }, (_, index) => (

                            isService || queryParams.get('type') === 'service' ? (
                                <li className="rounded flex items-center justify-between gap-4 hover:bg-neutral-10 py-4 px-2 transition-all duration-200 ease-in-out">
                                    <div className="text grid gap-1">
                                        <h5 className="text-base font-bold text-neutral-90 line-clamp-1">Cabelo e barba</h5>
                                        <p className="text-sm font-normal text-neutral-50">02/10 às 18:00</p>
                                        <p className="text-base font-normal text-neutral-90">R$ 50,00</p>
                                    </div>
                                    <button className="remove">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.89575 17.2918C5.46606 17.2918 5.09822 17.1388 4.79223 16.8328C4.48625 16.5269 4.33325 16.159 4.33325 15.7293V5.06266H4.302C4.08672 5.06266 3.9027 4.98627 3.74992 4.8335C3.59714 4.68072 3.52075 4.49669 3.52075 4.28141C3.52075 4.06613 3.59714 3.88211 3.74992 3.72933C3.9027 3.57655 4.08672 3.50016 4.302 3.50016H7.54159V3.48975C7.54159 3.27447 7.61797 3.09044 7.77075 2.93766C7.92353 2.78488 8.10756 2.7085 8.32284 2.7085H11.6978C11.9131 2.7085 12.0971 2.78488 12.2499 2.93766C12.4027 3.09044 12.4791 3.27447 12.4791 3.48975V3.50016H15.7187C15.9339 3.50016 16.118 3.57655 16.2708 3.72933C16.4235 3.88211 16.4999 4.06613 16.4999 4.28141C16.4999 4.49669 16.4235 4.68072 16.2708 4.8335C16.118 4.98627 15.9339 5.06266 15.7187 5.06266H15.6874V15.7293C15.6874 16.159 15.5344 16.5269 15.2284 16.8328C14.9225 17.1388 14.5546 17.2918 14.1249 17.2918H5.89575ZM14.1249 5.06266H5.89575V15.7293H14.1249V5.06266ZM7.60409 14.0939H9.16659V6.69808H7.60409V14.0939ZM10.8541 14.0939H12.4166V6.69808H10.8541V14.0939Z" fill="#1C1C1E" />
                                        </svg>
                                    </button>
                                </li>
                            ) : (
                                <li className="rounded flex items-center gap-4 hover:bg-neutral-10 py-4 px-2 transition-all duration-200 ease-in-out">
                                    <div className="w-[80px] h-[80px] rounded-full overflow-hidden flex-shrink-0">
                                        <img src="https://images.unsplash.com/photo-1568901346375-23c9450c58cd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1998&q=80" alt="" className="w-full h-full object-cover object-center" />
                                    </div>
                                    <div className="text grid gap-1">
                                        <h5 className="text-base font-bold text-neutral-90 line-clamp-1">[nome do produto]</h5>
                                        <p className="text-sm font-normal text-neutral-50">1x Batata frita</p>
                                        <p className="text-base font-normal text-neutral-90">R$ 27,80</p>
                                    </div>
                                    <div className="group flex items-center justify-center border border-neutral-20 rounded p-3 gap-4 min-w-[116px]">
                                        <svg className="cursor-pointer" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.89587 17.2916C5.46618 17.2916 5.09834 17.1386 4.79235 16.8326C4.48637 16.5266 4.33337 16.1588 4.33337 15.7291V5.06242H4.30212C4.08685 5.06242 3.90282 4.98603 3.75004 4.83325C3.59726 4.68047 3.52087 4.49645 3.52087 4.28117C3.52087 4.06589 3.59726 3.88186 3.75004 3.72909C3.90282 3.57631 4.08685 3.49992 4.30212 3.49992H7.54171V3.4895C7.54171 3.27422 7.6181 3.0902 7.77087 2.93742C7.92365 2.78464 8.10768 2.70825 8.32296 2.70825H11.698C11.9132 2.70825 12.0973 2.78464 12.25 2.93742C12.4028 3.0902 12.4792 3.27422 12.4792 3.4895V3.49992H15.7188C15.9341 3.49992 16.1181 3.57631 16.2709 3.72909C16.4237 3.88186 16.5 4.06589 16.5 4.28117C16.5 4.49645 16.4237 4.68047 16.2709 4.83325C16.1181 4.98603 15.9341 5.06242 15.7188 5.06242H15.6875V15.7291C15.6875 16.1588 15.5345 16.5266 15.2286 16.8326C14.9226 17.1386 14.5547 17.2916 14.125 17.2916H5.89587ZM14.125 5.06242H5.89587V15.7291H14.125V5.06242ZM7.60421 14.0937H9.16671V6.69784H7.60421V14.0937ZM10.8542 14.0937H12.4167V6.69784H10.8542V14.0937Z" fill="#1C1C1E" />
                                        </svg>
                                        <span>1</span>
                                        <svg className="cursor-pointer" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.16675 10.8334H5.00008C4.76397 10.8334 4.56605 10.7536 4.40633 10.5938C4.24661 10.4341 4.16675 10.2362 4.16675 10.0001C4.16675 9.76397 4.24661 9.56605 4.40633 9.40633C4.56605 9.24661 4.76397 9.16675 5.00008 9.16675H9.16675V5.00008C9.16675 4.76397 9.24661 4.56605 9.40633 4.40633C9.56605 4.24661 9.76397 4.16675 10.0001 4.16675C10.2362 4.16675 10.4341 4.24661 10.5938 4.40633C10.7536 4.56605 10.8334 4.76397 10.8334 5.00008V9.16675H15.0001C15.2362 9.16675 15.4341 9.24661 15.5938 9.40633C15.7536 9.56605 15.8334 9.76397 15.8334 10.0001C15.8334 10.2362 15.7536 10.4341 15.5938 10.5938C15.4341 10.7536 15.2362 10.8334 15.0001 10.8334H10.8334V15.0001C10.8334 15.2362 10.7536 15.4341 10.5938 15.5938C10.4341 15.7536 10.2362 15.8334 10.0001 15.8334C9.76397 15.8334 9.56605 15.7536 9.40633 15.5938C9.24661 15.4341 9.16675 15.2362 9.16675 15.0001V10.8334Z" fill="#1C1C1E" />
                                        </svg>
                                    </div>
                                </li>
                            )
                        ))}
                    </ul>

                    {isCart && (
                        <footer className="grid gap-6 w-full pt-6 border-t-2 border-t-neutral-20">
                            <ul className="text-base font-normal neutral-90 grid gap-3">
                                <li className="flex justify-between">
                                    <span>Subtotal</span>
                                    <span>R$ 57,70</span>
                                </li>
                                <li className="flex justify-between">
                                    <span>{ queryParams.get('type') === 'service' ? 'Taxa de serviço' : 'Entrega'}</span>
                                    <span>R$ 6,00</span>
                                </li>
                                <li className="flex justify-between font-bold">
                                    <span>Total</span>
                                    <span>R$ 63,70</span>
                                </li>
                            </ul>
                            <button className="text-neutral-0 text-sm font-bold bg-main-30 py-3 px-4 rounded hover:bg-main-20 transition-all duration-200 ease-in-out disabled:text-neutral-20 disabled:bg-neutral-40 w-full" disabled>Finalizar compra</button>
                        </footer>
                    )}
                </div>
            )}
        </>
    );
};

export default Summary;

