import { useEffect } from 'react';
import axios from 'axios';

function ColorSetter() {
  useEffect(() => {
    axios.get('https://gist.githubusercontent.com/rhikson/2be9b4a807913adcfd0bf7d2283b244c/raw/3bdcf20f9c104659c7e3ca1877418328f824ba7d/colors.json')
      .then(response => {
        const mainColors = response.data.main_colors;
        const root = document.documentElement;

        console.log(root);

        // Defina as variáveis CSS customizadas com os dados da API
        root.style.setProperty('--main-0', mainColors.main_1);
        root.style.setProperty('--main-10', mainColors.main_2);
        root.style.setProperty('--main-20', mainColors.main_3);
        root.style.setProperty('--main-30', mainColors.main_4);
        root.style.setProperty('--main-40', mainColors.main_5);
      })
      .catch(error => {
        console.error('Erro ao buscar dados da API:', error);
      });
  }, []);

  return null; // Este componente não renderiza nada, apenas define as variáveis CSS
}

export default ColorSetter;
