import 'preline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import Summary from 'views/StoreDetail/components/Summary';
import PaymentForm from '../PaymentForm';
import Header from 'views/Home/components/Header';
import Footer from 'views/Static/Footer';
import { useSetup } from 'views/Home/hooks/useSetup';
import { defaultPaymentValues, paymentValidationSchema } from '../PaymentForm/PaymentForm';
import { useCartContext } from 'views/ShoppingCart/contexts/cart/CartProvider';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import { useStore } from '../../../StoreDetail/hooks/useStore';
import { useCart } from '../../../ShoppingCart/hooks/cart';

const Cart = () => {
  const navigate = useNavigate();
  const { data: setupData } = useSetup();

  const methods = useForm({
    defaultValues: defaultPaymentValues.defaultValues,
    // @ts-ignore
    resolver: yupResolver(paymentValidationSchema),
  });

  const { id } = useParams();
  const { data: storeData } = useStore(id);

  const { total } = useCartContext();
  const orderDeliveryValue = storeData?.order_delivery_value?.replace('R$ ', '').replace(',', '.').trim();
  const formattedOrderDeliveryValue = Number(orderDeliveryValue);
  const { submitOrder, products, clearCart } = useCart();

  const onSubmit = async (formData: any) => {
    const {
      name, phone, email, paymentType,
      complement, valor, needsChange
    } = formData;
    const totalValue = formattedOrderDeliveryValue + total.totalPrice;
    const payload = {
      store_id: Number(id),
      full_name: name,
      phone,
      email,
      address: `${setupData?.setup_address?.street}, ${setupData?.setup_address?.number} - ${setupData?.setup_address?.neighborhood} - ${setupData?.setup_address?.city} - Cep: ${setupData?.setup_address?.cep}`,
      complement,
      payment: paymentType,
      payment_observation: needsChange ? `Precisa de troco R$ ${valor}` : '',
      order_observation: '',
      subtotal: total.totalPrice,
      delivery: formattedOrderDeliveryValue,
      total: totalValue,
      formatted_total: `R$ ${totalValue}`,
      cart_items: products.map((p: any) => ({
        product_id: id,
        quantity: p?.quantity
      })),
    };
    try {
      const { data } = await submitOrder(payload);
      clearCart();
      navigate(`/success/${data.data.order_number}`);
    } catch(e) {
      console.log('error after create order');
    }
  };

  return (
    <>
      <Header />
      <section className="s-cart w-full mt-6">
        <FormProvider {...methods}>
          <form name="payment" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="container px-2 md:px-4 lg:px-6 xxl:px-0 grid md:grid-cols-12 gap-6">
              <div className="md:col-start-1 md:col-end-9">
                <div className="text grid gap-4 mb-8">
                  <h3 className="text-neutral-90 text-4xl font-semibold">Informações</h3>
                  <p className="text-neutral-90 text-base font-normal">Preencha os dados e finalize seu pedido</p>
                </div>
                <PaymentForm />
              </div>
              <div className="md:col-start-9 md:col-end-13">
                <Summary />
              </div>
            </div>
          </form>
        </FormProvider>
      </section>
      <Footer />
    </>
  );
};

export default Cart;
