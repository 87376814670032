import { useCartContext } from 'views/ShoppingCart/contexts/cart/CartProvider';
import useCartTotal from './useCartTotal';
import { Product } from 'views/StoreDetail/types';

export const useCartProducts = () => {
  const { products, setProducts } = useCartContext();
  const { updateCartTotal } = useCartTotal();

  const updateQuantitySafely = (
    currentProduct: Product,
    targetProduct: Product,
    remove?: boolean,
  ): Product => {
    if (currentProduct.id === targetProduct.id) {
      return Object.assign({
        ...currentProduct,
        quantity: remove ? 0 : (currentProduct.quantity ?? 0) + 1,
      });
    } else {
      return currentProduct;
    }
  };

  const addProduct = (newProduct: Product) => {
    let updatedProducts: Product[] = [];
 
    const isProductAlreadyInCart =  products.some(
      (product: Product) => newProduct.id === product.id
    );

    if (isProductAlreadyInCart) {
      updatedProducts = products.map((product: Product) => {
        return updateQuantitySafely(product, newProduct);
      });
      setProducts(updatedProducts);
      updateCartTotal(updatedProducts);
    } else {
      setProducts([...products, newProduct]);
      updateCartTotal([...products, newProduct]);
    }
  };

  const removeProductFromCart = (produtToRemove: Product) => {
    let updatedProducts: Product[] = [];

    const isProductAlreadyInCart =  products.some(
      (product: Product) => produtToRemove.id === product.id
    );

    if (isProductAlreadyInCart) {
      updatedProducts = products.filter((product: Product) => {
        return produtToRemove?.id !== product?.id;
      });
      setProducts(updatedProducts);
      updateCartTotal(updatedProducts);
    }
  };

  const clearCart = () => {
    setProducts([]);
    updateCartTotal([]);
  };

  const decreaseProductQuantity = (produtToRemove: Product) => {
    const decreasedList: Product[] = products.map(p => {
      if (p.id === produtToRemove.id) {
        return { ...p, quantity: (p?.quantity ?? 0) - 1 };
      }
      return p;
    });

    setProducts(decreasedList);
    updateCartTotal(decreasedList);
  };

  return {
    decreaseProductQuantity,
    removeProductFromCart,
    updateQuantitySafely,
    addProduct,
    products,
    clearCart
  };
};
