import { useState, SyntheticEvent } from 'react';
import {
  useNavigate
} from 'react-router-dom';

type Props = {
  defaultValue?: string;
};

const Search = ({ defaultValue }: Props) => {
  const navigate = useNavigate();
  const [term, setTermValue] = useState<string | undefined>(defaultValue ?? '');

  const onChangeTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermValue(e.target.value);
  };

  const onSubmitSearch = (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();
    navigate(`/search?type=stores&q=${term}`, { state: { term } });
  };

  const onSubmitClick = () => {
    navigate(`/search?type=stores&q=${term}`);
  };

  return (
    <div className="flex flex-col gap-8 justify-self-end">
      <form onSubmit={onSubmitSearch} className='relative z-10 flex space-x-3 bg-white border border-neutral-10 rounded-lg w-full'>
        <div className="flex-[1_0_0%]">
            <label htmlFor="minute-prices-search" className="block text-sm text-neutral-50 font-normal"><span className="sr-only">Busque por empresa, produto ou serviço</span></label>
            <input
              value={term}
              onChange={onChangeTerm}
              type="text"
              name="minute-prices-search"
              id="minute-prices-search"
              className="
                text-sm
                text-neutral-50
                font-normal
                py-3 px-3
                block w-full
                border-transparent
                rounded-lg
                focus:outline-none
              "
              style={{ minWidth: 294 }}
              placeholder="Busque por empresa, produto ou serviço"
            />
        </div>
        <div className="flex-[0_0_auto] flex items-center px-3">
          <button
            onClick={onSubmitClick}
            className="w-[24px] h-[24px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold disabled:pointer-events-none">
            <svg onClick={onSubmitClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.54145 15.85C7.77258 15.85 6.27554 15.2379 5.05033 14.0137C3.82511 12.7894 3.2125 11.2936 3.2125 9.52616C3.2125 7.75873 3.82462 6.26251 5.04885 5.03751C6.27308 3.81251 7.76892 3.20001 9.53635 3.20001C11.3038 3.20001 12.8 3.81262 14.025 5.03784C15.25 6.26305 15.8625 7.7601 15.8625 9.52896C15.8625 10.243 15.75 10.9229 15.525 11.5688C15.3 12.2146 14.9917 12.7833 14.6 13.275L20.1404 18.8035C20.3218 18.9845 20.4125 19.2042 20.4125 19.4625C20.4125 19.7208 20.3208 19.9417 20.1375 20.125C19.9542 20.3083 19.7333 20.4 19.475 20.4C19.2167 20.4 18.9969 20.3094 18.8156 20.1283L13.279 14.5875C12.7847 14.9875 12.2167 15.2979 11.575 15.5188C10.9333 15.7396 10.2555 15.85 9.54145 15.85ZM9.5375 13.975C10.7792 13.975 11.8313 13.5438 12.6938 12.6813C13.5563 11.8188 13.9875 10.7667 13.9875 9.52501C13.9875 8.28335 13.5563 7.23126 12.6938 6.36876C11.8313 5.50626 10.7792 5.07501 9.5375 5.07501C8.29584 5.07501 7.24375 5.50626 6.38125 6.36876C5.51875 7.23126 5.0875 8.28335 5.0875 9.52501C5.0875 10.7667 5.51875 11.8188 6.38125 12.6813C7.24375 13.5438 8.29584 13.975 9.5375 13.975Z" fill="#F28705" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;
