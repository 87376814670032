// import { useState, useEffect } from 'react';
// import store from 'store';
import {
  Link,
  useLocation
} from 'react-router-dom';
import FilterCategory from './components/FilterCategory';
import Company from './components/Company';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

import Header from './components/Header';
import Footer from 'views/Static/Footer';
import Highlighted from './components/Highlighted';
// import Nearby from './components/Nearby';
import { useSetup } from './hooks/useSetup';
import Search from 'components/shared/Search';

const Home = () => {
  const { data } = useSetup();
  const location = useLocation();

  return (
    <>
      <Header>
        <Search />
      </Header>
      <main className="w-full flex flex-col gap-8 md:gap-10 my-6 md:my-16">
        {/* Categories */}
        <section className="s-categories overflow-hidden">
          <FilterCategory />
        </section>
          {/* Categories */}
        {/* Sponsors */}
        {data?.banners && !location?.search ? (
          <section className="s-sponsors">
            <div className="container px-4 lg:px-6 xxl:px-0">
              <Swiper
                spaceBetween={12}
                slidesPerView={1}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                  },
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                onSlideChange={() => console.log('sponsors change')}
                className="inline-block w-full"
              >
                {(data?.banners || []).map((b: { banner: string; link: string }, index) => {
                  return (
                    <SwiperSlide className="min-w-[335px] lg:max-w-none" key={index}>
                      <Link className="w-full h-[200px] rounded-lg flex items-center justify-center" to={b.link}>
                        <img src={b?.banner} alt="Logo" />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </section>
        ) : null}
        {/* Sponsors */}

        {/* <Nearby /> */}
        {!location?.search ? <Highlighted /> : null}

        {/* Stores */}
        <Company />
        {/* Stores */}
      </main>
      <Footer />
    </>
  );
};

export default Home;