import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <header className="w-full">
                <div className="container py-6 px-2 md:px-4 lg:px-6 xxl:px-0 flex gap-6 items-center justify-between">
                    <Link to="/" className="w-[116px] h-[24px]">
                        <img src="https://s3-alpha-sig.figma.com/img/9929/367b/f1e18971f14a0236262d93037b2c97cf?Expires=1696809600&Signature=UEc1a~NFUjq5x6lh38iYVUbPqof3zJJKjp2fp7Qdu8MPrpuoq2LLkXbXvWkh9eF5xaHVaNhMUMWzvG9tyVpJeudPlPbwIl0rhrkW8XOob-0w-6j9lyHe8Z6m10Bmsfa1p~6WUuuuE6OBA7iRuEfuEXloPqrJ-afxk~gszWPZcANeQdK7L-TgBZDcfAHfes8Belx05jO1jgD8iPdLo7idK33jEAhF1765PftFzM4idV7Bth0EmHJ6ddyU6RysZx6X57OlnZ9j9sZYfGQh7zyOTvURE8~GgHXKR~7Sv8akYYe9MIDSrBby~AF0tb4acwszpl7qS8hLZ3Q0WmIlMwyfYQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" alt="Logo" />
                    </Link>

                    <Link className="font-normal text-sm text-neutral-90 hover:text-neutral-60 transition-colors duration-200 ease-in-out" to="/">Vila Rica do Brasil</Link>
                </div>
            </header>
        </>
    );
};

export default Header;

