import store from 'store';
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSetup } from '../../hooks/useSetup';

const Header = ({ children, setOpenCheckout }: any) => {
  const [internalOpen, setInternalOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data } = useSetup();
  const imageUrl = data?.unit_logo_url;
  const imageSizeWidth = data.unit_logo_size ?? 200;
  const isCart = pathname.includes('/carrinho-de-compras');
  const isRestaurant = pathname.includes('/restaurante');
  const isSuccess = pathname.includes('/success');
  const isHome = pathname === '/';
  console.log(' pathname ==>', pathname);

  const openCheckout = () => {
    setOpenCheckout?.(true);
    setInternalOpen(true);
    store.set('isOpenSummaryCart', true);
    // @ts-ignore
    window?.HSOverlay?.open(document.querySelector('#overlay-checkout'));
  };

  useEffect(() => {
    if (internalOpen !== store.get('isOpenSummaryCart')) {
      setInternalOpen(store.get('isOpenSummaryCart'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.get('isOpenSummaryCart'), internalOpen]);

  const redirectBack = () => navigate(-1);

  const closeSummaryCart = () => {
    store.set('isOpenSummaryCart', false);
    setInternalOpen(false);
  // @ts-ignore
    window?.HSOverlay?.close(document.querySelector('#overlay-checkout'));
  };

  const onClickBack = () => {
    if (isRestaurant && store.get('isOpenSummaryCart')) {
      store.set('isOpenSummaryCart', false);
      setInternalOpen(false);
      closeSummaryCart();
    } else if (isRestaurant && !store.get('isOpenSummaryCart')) {
      redirectBack();
    }
  };

  return (
    <header className='sticky top-0 z-40 w-full backdrop-blur bg-white/80 border-b border-neutral-10'>
      <div className="container px-2 md:px-0">
        {/* Desktop */}
        <div className="hidden md:flex items-center justify-between">
          <div className="basis-1/2 flex items-center py-6 px-2 md:px-4 lg:px-6 xxl:px-0 gap-6">
            {imageUrl && (
              <Link to="/" className={`"w-[${imageSizeWidth}px] h-auto"`}>
                <img src={imageUrl} alt="Logo" width={imageSizeWidth} loading="lazy" />
              </Link>
            )}
            <Link className="justify-self-start font-normal text-sm text-neutral-90 hover:text-neutral-60 transition-colors duration-200 ease-in-out" to="/">{data?.setup_name}</Link>
          </div>
          <div className="py-6 px-2 md:px-4 lg:px-6 xxl:px-0">
            {children}
          </div>
        </div>

        {/* Mobile */}
        <div className="md:hidden py-4 flex items-center justify-between">
          {/* Back button */}
            <button onClick={isSuccess ? () => navigate('/') : onClickBack} type="button" className="size-6">
              {isCart || isRestaurant || isSuccess ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8 12L14.7 15.9C14.8833 16.0833 14.975 16.3167 14.975 16.6C14.975 16.8833 14.8833 17.1167 14.7 17.3C14.5167 17.4833 14.2833 17.575 14 17.575C13.7167 17.575 13.4833 17.4833 13.3 17.3L8.69999 12.7C8.59999 12.6 8.52915 12.4917 8.48749 12.375C8.44582 12.2583 8.42499 12.1333 8.42499 12C8.42499 11.8667 8.44582 11.7417 8.48749 11.625C8.52915 11.5083 8.59999 11.4 8.69999 11.3L13.3 6.69999C13.4833 6.51665 13.7167 6.42499 14 6.42499C14.2833 6.42499 14.5167 6.51665 14.7 6.69999C14.8833 6.88332 14.975 7.11665 14.975 7.39999C14.975 7.68332 14.8833 7.91665 14.7 8.09999L10.8 12Z" fill="#1C1C1E" />
                </svg>
              ) : null}
            </button>
          {/* Logo */}
          <p className='text-sm text-neutral-90'> {isCart || isSuccess ? 'Carrinho' : (
            imageUrl && (
              <Link to="/" className={`"w-[${imageSizeWidth}px] h-auto"`}>
                <img src={imageUrl} alt="Logo" width={95} loading="lazy" />
              </Link>
            )
          )} </p>
          {/* Cart button */}
            <button onClick={openCheckout} type="button">
              {!isSuccess && !isHome ? (
                <svg onClick={openCheckout} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path onClick={openCheckout} d="M5.62501 20.75C5.20834 20.75 4.83334 20.6229 4.50001 20.3687C4.16668 20.1146 3.94168 19.7833 3.82501 19.375L1.31251 10.3125C1.22918 10.0125 1.28126 9.73957 1.46876 9.49374C1.65626 9.2479 1.90418 9.12499 2.21251 9.12499H6.92501L11.2 2.72499C11.2796 2.59905 11.3909 2.49674 11.5341 2.41804C11.6773 2.33934 11.8284 2.29999 11.9875 2.29999C12.1393 2.29999 12.2835 2.33749 12.4201 2.41249C12.5567 2.48749 12.6655 2.59017 12.7466 2.72054L17.025 9.12499H21.7875C22.0958 9.12499 22.3438 9.2479 22.5313 9.49374C22.7188 9.73957 22.7708 10.0125 22.6875 10.3125L20.175 19.375C20.0583 19.7833 19.8333 20.1146 19.5 20.3687C19.1667 20.6229 18.7917 20.75 18.375 20.75H5.62501ZM5.60001 18.875H18.4L20.575 11H3.42501L5.60001 18.875ZM12.0011 16.8C12.517 16.8 12.9583 16.6163 13.325 16.2489C13.6917 15.8815 13.875 15.4398 13.875 14.9239C13.875 14.408 13.6913 13.9667 13.3239 13.6C12.9565 13.2333 12.5148 13.05 11.9989 13.05C11.483 13.05 11.0417 13.2337 10.675 13.6011C10.3083 13.9685 10.125 14.4102 10.125 14.9261C10.125 15.442 10.3087 15.8833 10.6761 16.25C11.0435 16.6167 11.4852 16.8 12.0011 16.8ZM9.21251 9.12499H14.775L11.9875 4.94999L9.21251 9.12499Z" fill="#1C1C1E" />
                </svg>
              ) : null}
            </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
