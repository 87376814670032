import { Link } from 'react-router-dom';
import { useSetup } from '../../Home/hooks/useSetup';

const Footer = ({ children, setOpenCheckout }: any) => {
    const { data } = useSetup();
    const imageUrl = data?.unit_logo_url;

    return (
        <div style={{ width: '100%' }}>
            <footer className="w-full mt-16">
                <div className="top border-t border-neutral-10">
                    <div className="container px-2 md:px-4 lg:px-6 xxl:px-0 flex flex-col md:flex-row gap-6 md:items-start justify-between">
                        {imageUrl && (
                            <>
                                <div className="w-full max-w-[194px] pt-8 md:pt-6 md:pb-10">
                                    <img src={imageUrl} alt="Logo" className="max-h-[24px]" />
                                </div>
                            </>
                        )}
                        <div className="left flex gap-6 w-full md:pt-6">
                            <div className="block-links max-w-[304px] w-full flex flex-col gap-4 md:gap-6 md:pb-10">
                                <h6 className="text-xs font-semibold text-neutral-60">{data?.setup_name}</h6>
                                <ul className="flex flex-col gap-4">
                                    <li>
                                        <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                            Site institucional
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                            Área interna
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                            Blog
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="block-links max-w-[304px] w-full flex flex-col gap-4 md:gap-6 md:pb-10">
                                <h6 className="text-xs font-semibold text-neutral-60">Social</h6>
                                <ul className="flex flex-col gap-4">
                                    <li>
                                        <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                            Instagram
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                            LinkedIn
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                            YouTube
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                            Facebook
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="block-links max-w-[304px] w-full flex flex-col gap-4 md:gap-6 border-t md:border-t-0 md:border-l border-neutral-10 pt-6 pb-8 md:pb-10 md:ps-6">
                            <h6 className="text-xs font-semibold text-neutral-60">Sobre a Minute Prices</h6>
                            <ul className="flex flex-col gap-4">
                                <li>
                                    <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                        Cadastre sua empresa
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                        Quem somos
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                        Fale conosco
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 hover:text-neutral-90 transition-colors duration-300 ease-in-out">
                                        Facebook
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bottom border-t border-neutral-10 py-6">
                    <div className="container px-2 md:px-4 lg:px-6 xxl:px-0 flex flex-col md:flex-row gap-6 md:items-center justify-between">
                        <div className="left flex flex-col md:flex-row gap-6 md:items-center">
                            <p className="text-sm font-normal text-neutral-60">Liveprint © 2023 - Todos os Direitos Reservados</p>
                            <ul className="flex flex-col md:flex-row gap-6">
                                <li>
                                    <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 underline hover:text-neutral-60/80 transition-colors duration-300 ease-in-out">
                                        Política de privacidade
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 underline hover:text-neutral-60/80 transition-colors duration-300 ease-in-out">
                                        Termos de uso
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="right">
                            <Link to="https://liveprint.com.br" target="_blank" className="text-sm font-normal text-neutral-60 underline md:no-underline hover:text-neutral-60/80 transition-colors duration-300 ease-in-out">
                                Liveprint Pages
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </div >
    );
};

export default Footer;

